html {
	background-color: #000000;
	background-image: linear-gradient(147deg, #000000 0%, #151c23 74%);
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	height: 100%;
}
body {
	margin: 0;
	font-size: 16px;
	line-height: 1.5;
	color: #fff;
	user-select: none;
}
a {
	text-decoration: none;
}
p {
	margin: 0;
	padding: 0;
}
li {
	list-style: none;
}
ul {
	padding: 0;
	margin: 0;
}
h1,h2,h3,h4,h5,h6,h7 {
	margin: 0;
}
input::-ms-clear {
	display: none;
}
input:focus {
	outline: none !important;
}
input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
button:active, button:focus {
	outline: none;
}
button::-moz-focus-inner {
	border: 0;
	outline: 0;
}
button {
	cursor: pointer;
	border: 0;
	outline: 0;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color: rgba(0, 125, 254, 0.5);
	opacity: 1;
}
::-moz-placeholder { /* Firefox 19+ */
	color: rgba(0, 125, 254, 0.5);
	opacity: 1;
}
:-ms-input-placeholder { /* IE 10+ */
	color: rgba(0, 125, 254, 0.5);
	opacity: 1;
}
:-moz-placeholder { /* Firefox 18- */
	color: rgba(0, 125, 254, 0.5);
	opacity: 1;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type="number"] {
	-moz-appearance: textfield;
}
input[type="number"]:hover,
input[type="number"]:focus {
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.dropdown {
	position: relative;
}
.dropdown-toggle::after {
	content: '▼';
	border: none;
	position: relative;
	top: -1px;
	right: 0;
	padding: 0 2px 0 6px;
	margin: 0 0.3em 0 0;
	vertical-align: 0;
	font-size: 0.6rem;
	font-weight: 700;
	color: #fff;
}
.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 10rem;
	padding: .5rem 0;
	margin: .125rem 0 0;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	background-color: #FFF;
	background-clip: padding-box;
	border: 1px solid rgba(0,0,0,.15);
	border-radius: .25rem;
}
.dropdown-item {
	display: block;
	width: 100%;
	padding: .25rem 1.5rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	background-color: transparent;
	border: 0;
}
.dropdown:hover .dropdown-menu {
	display: block;
}
.dropdown-menu a:before {
	content: '➤';
	padding-right: 0.5rem;
	font-weight: 700;
	font-size: 0.55rem;
}
.top-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 25px 0;
}
.mobile-header {
	display: none;
}
.logo {
	display: block;
}
.logo img {
	width: 125px;
	height: 63px;
	-o-object-fit: scale-down;
	object-fit: scale-down;
}
.navbar {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.navbar-list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.navbar-list-item {
	margin-right: 45px;
}
.navbar-list-item-link {
	padding: 2px 16px;
	border-radius: 20px;
	color: #ffffff;
	font-family: 'Gilroy-Black';
	font-style: normal;
	font-weight: 900;
	font-size: 24px;
	line-height: 30px;
}
.active .navbar-list-item-link {
	background: #242628;
	color: #fff;
}
.navbar-list-item-link:hover {
	background: #242628;
	color: #fff;
}
.connect-wallet {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background: #665e8140;
	-webkit-box-shadow: 0px 3px 4px rgba(0, 16, 71, 0.25);
	box-shadow: 0px 3px 4px rgba(0, 16, 71, 0.25);
	border-radius: 10px;
	padding: .5em 1em;
	font-family: 'Gilroy-Black';
	font-style: normal;
	font-weight: 900;
	font-size: 1.2em;
	line-height: 30px;
	color: #F2F2F2;
}
.connect-wallet img {
	max-width: 1.5em;
	margin-right: 1em;
}
.title-h1 {
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 64px;
	line-height: 75px;
	text-align: center;
	color: #333333;
	text-transform: uppercase;
	margin: 100px 150px 0 150px;
}
.main {
	padding-bottom: 50px;
}
.main__text {
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 21px;
	line-height: 25px;
	text-align: center;
	color: #333333;
	margin: 65px 360px 50px 360px;
}
.main-btn-group {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin: 0 360px;
}
.btn {
	border-color: #665e81;
	border-radius: 12px;
	border-width: 1px;
	border-style: solid;
	background: #00000000;
	box-shadow: 0px 3px 4px rgba(0, 16, 71, 0.25);
	font-family: 'Gilroy-Black';
	font-style: normal;
	font-weight: 900;
	font-size: 1em;
	line-height: 1.2em;
	text-align: center;
	color: #F2F2F2;
	padding: .5em 0;
	display: block;
	text-transform: uppercase;
	outline: 0;
	transition: 100ms ease-in-out;
}
.btn:hover:enabled {
	background: #665e8144;
}
.number {
	padding: 60px 0 100px 0;
}
.number-card {
	margin-bottom: 25px;
}
.number-card__number {
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 64px;
	line-height: 75px;
	text-align: center;
	color: #333333;
}
.number-card__text {
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 21px;
	line-height: 25px;
	text-align: center;
	color: #333333;
	margin-top: 15px;
}
main {
	background: url(../image/bg2.png);
	background-size: cover;
}
.center {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.slide {
	text-align: center;
}
.title-h2 {
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 48px;
	line-height: 56px;
	text-align: center;
	text-transform: uppercase;
	color: #333333;
	margin: 0 200px;
}
.slide__text {
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 21px;
	line-height: 25px;
	text-align: center;
	color: #333333;
	margin: 20px 0 35px 0;
}
.slide__image img {

}
.what-flex {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.flex-item {
	width: 100%;
}
.flex-item:not(:last-child) {
	margin-right: 10px;
}
.what {
	padding: 110px 0;
}
.what-card {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	padding: 0 35px;
	height: 229px;
	position: relative;
}
.what-card::before {
	position: absolute;
	z-index: 1;
	content: "";
	width: 100%;
	height: 100%;
	opacity: 0.6;
	background: -webkit-gradient(linear, left top, left bottom, from(#7000FF), to(#A600E0));
	background: -o-linear-gradient(top, #7000FF 0%, #A600E0 100%);
	background: linear-gradient(180deg, #7000FF 0%, #A600E0 100%);
	border: 1px solid #FFFFFF;
	-webkit-box-shadow: 0px 3px 4px rgba(0, 16, 71, 0.25);
	box-shadow: 0px 3px 4px rgba(0, 16, 71, 0.25);
	border-radius: 20px;
}
.what-card__image {
	background: #fff;
	width: 85px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 85px;
	background: #F2F2F2;
	-webkit-box-shadow: 0px 3px 4px rgba(0, 16, 71, 0.25);
	box-shadow: 0px 3px 4px rgba(0, 16, 71, 0.25);
	border-radius: 25px;
	margin-bottom: 15px;
	z-index: 2;
	position: relative;
	margin-top: 35px;
}
.what-card__text {
	font-family: 'Gilroy-Black';
	font-style: normal;
	font-weight: 900;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #F2F2F2;
	z-index: 2;
	position: relative;
}
.what-flex .active .what-card__image {
	background: #242628;
}
.what-flex .active .what-card__text{
	color: #400F81;
}
.btn-center {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-top: 60px;
}
.margin {
	margin: 0 250px;
}
.zyx-card {
	padding: 40px 0;
	text-align: center;
	margin-bottom: 30px;
	position: relative;
}
.zyx-card::before {
	width: 100%;
	height: 100%;
	opacity: 0.6;
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	background: -o-linear-gradient(bottom, rgba(2, 49, 170, 0.33), rgba(2, 49, 170, 0.33)), -o-linear-gradient(184.08deg, #113591 4.41%, #7AE7FF 56.8%, #C923F2 106.01%);
	background: linear-gradient(0deg, rgba(2, 49, 170, 0.33), rgba(2, 49, 170, 0.33)), linear-gradient(265.92deg, #113591 4.41%, #7AE7FF 56.8%, #C923F2 106.01%);
	border: 1px solid #467AFF;
	-webkit-box-shadow: 0px 3px 4px rgba(0, 16, 71, 0.25);
	box-shadow: 0px 3px 4px rgba(0, 16, 71, 0.25);
	border-radius: 20px;
	z-index: 1;
}
.zyx__text {
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 21px;
	line-height: 25px;
	text-align: center;
	color: #333333;
	margin: 40px 0 40px 0;
}
.zyx-card__title {
	font-family: 'Gilroy-Black';
	font-style: normal;
	font-weight: 900;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #400F81;
	margin-top: 10px;
	opacity: 1;
	z-index: 2;
	position: relative;
}
.zyx-card__image img {
	width: 100px;
	height: 100px;
	-o-object-fit: scale-down;
	object-fit: scale-down;
	opacity: 1;
	z-index: 2;
	position: relative;
}
.join {
	margin: 110px 0 70px 0;
	padding-bottom: 60px;
}
.join-card {
	padding: 50px 0;
	text-align: center;
	position: relative;
}
.join-card::before {
	width: 100%;
	height: 100%;
	opacity: 0.6;
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	background: -webkit-gradient(linear, left top, left bottom, from(#7000FF), to(#A600E0));
	background: -o-linear-gradient(top, #7000FF 0%, #A600E0 100%);
	background: linear-gradient(180deg, #7000FF 0%, #A600E0 100%);
	opacity: 0.6;
	border: 1px solid #FFFFFF;
	-webkit-box-shadow: 0px 3px 4px rgba(0, 16, 71, 0.25);
	box-shadow: 0px 3px 4px rgba(0, 16, 71, 0.25);
	border-radius: 20px;
	z-index: 1;
}
.join .row {
	margin: 25px 200px 0 200px;
}
.join-card__image {
	display: block;
	position: relative;
	z-index: 2;
}
.footer {
	margin-top: 2em;
}
.footer-flex {
	display: flex;
	justify-content: center;
}
.footer-flex-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.footer-flex-item-list {
	margin-right: 100px;
}
.footer__title {
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	color: #000000;
}
.footer-list-item-link {
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 100;
	font-size: 18px;
	line-height: 22px;
	color: #000000;
}
.subscribe__title {
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 21px;
	line-height: 25px;
	color: #000000;
	margin-bottom: 10px;
}
.subscribe-form {
	margin-bottom: 25px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.subscribe-input {
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	color: #333333;
	background: rgba(242, 242, 242, 0.2);
	border: 1px solid #400F81;
	border-radius: 50px;
	padding: 13px 0 13px 18px;
}
.subscribe-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color: #400F81;
	opacity: 1;
}
.subscribe-input::-moz-placeholder { /* Firefox 19+ */
	color: #400F81;
	opacity: 1;
}
.subscribe-input:-ms-input-placeholder { /* IE 10+ */
	color: #400F81;
	opacity: 1;
}
.subscribe-input:-moz-placeholder { /* Firefox 18- */
	color: #400F81;
	opacity: 1;
}
.subscribe-btn {
	width: 146px;
	-webkit-box-shadow: none;
	box-shadow: none;
	margin-left: 5px;
}
.footer__copy {
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 21px;
	line-height: 25px;
	color: #000000;
}
.subscribe-btn {
	text-transform: unset;
	padding: 14px 15px;
	font-size: 21px;
	line-height: 21px;
}
.checkbox input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}
.checkbox__text {
	position: relative;
	padding: 0 0 0 45px;
	cursor: pointer;
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 28px;
	color: #F2F2F2;
}
.checkbox__text span::first-letter {
	text-transform: uppercase;
}
.checkbox__text::before {
	content: '';
	position: absolute;
	left: 0;
	top: -1px;
	width: 29px;
	height: 29px;
	border: 2px solid #F2F2F2;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 5px;
	background: transparent;
}
.checkbox__text::after {
	content: '';
	position: absolute;
	left: 5px;
	top: 4px;
	width: 19px;
	height: 19px;
	background: #fff;
	border-radius: 5px;
	opacity: 0;
	-webkit-transition: .2s;
	-o-transition: .2s;
	transition: .2s;
}
.checkbox input:checked + .checkbox__text::after {
	opacity: 1;
}
.bg2 {
	background: url(../image/bg3.png);
	background-size: 100% 100%;
	background-repeat: no-repeat;
}
.open-modal {
	cursor: pointer;
}
.wallet-card-img {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 25px 80px;
}
.wallet {
	padding: 115px 0 150px 0;
}
.wallet-card {
	padding: 25px 35px;
}
.card-1 {
	background: #FFFFFF;
	-webkit-box-shadow: 0px 3px 4px rgba(0, 16, 71, 0.25);
	box-shadow: 0px 3px 4px rgba(0, 16, 71, 0.25);
	border-radius: 20px;
}
.card-2 {
	background: #FF6969;
	-webkit-box-shadow: 0px 3px 4px rgba(0, 16, 71, 0.25);
	box-shadow: 0px 3px 4px rgba(0, 16, 71, 0.25);
	border-radius: 20px;
	padding: 26px 35px;
}
.wallet-card__title {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-family: 'Gilroy-Black';
	font-style: normal;
	font-weight: 900;
	font-size: 24px;
	line-height: 30px;
	color: #333333;
}
.card-2 .wallet-card__title {
	color: #fff;
}
.wallet-card__title img {
	margin-right: 10px;
}
.wallet-card__text {
	margin: 40px 0;
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 28px;
	color: #F2F2F2;
}
.wallet-card-form {
	margin-bottom: 35px;
	text-align: center;
}
.swap {
	width:100%;
	display: flex;
	flex-direction: column;
	margin: 0 30%;
	max-width: 400px;
}

.from__input {
	border: 0;
	outline: 0;
	/*border-bottom: 2px solid #ffffff;*/
	background: transparent;
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 2em;
	color: #ffffff;
	width: 100%;
	display: block;
}
.from__input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color: #333333;
}
.from__input::-moz-placeholder { /* Firefox 19+ */
	color: #333333;
}
.from__input:-ms-input-placeholder { /* IE 10+ */
	color: #333333;
}
.from__input:-moz-placeholder { /* Firefox 18- */
	color: #333333;
}
.from-input {
	width: 70%;
	top: 20px;
}
.from-input span {
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	text-align: end;
	color: #333333;
	opacity: 0.5;
	width: 100%;
	display: block;
	margin-top: 12px;
}
.swap__title {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-family: 'Gilroy-Black';
	font-style: normal;
	font-weight: 900;
	font-size: 30px;
	line-height: 30px;
	text-align: center;
	color: #ffffff;
	margin-bottom: 30px;
}
.swap__title img {
	margin-right: 15px;
}
.from-block {
	background: #242628;
	box-shadow: rgba(21, 28, 35, 0.90) 0px 5px 3px 1px;
	border-radius: 20px;
	padding: 1em;
	border-style: solid;
	border-width: 1px;
	border-color: #4A4A4A;
	margin-bottom: .3em;
}
.swap__image {
	text-align: center;
	margin: -10px 0 -10px 0;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	z-index: 0;
	display: flex;
	justify-content: center;
	width: 1em;
	height: 1em;
	align-items: center;
	padding: 3px;
	border: 1px solid #5a5a5a;
	background: #242628;
	align-self: center;
	cursor: pointer;
	border-radius: 10px;
	transition: 100ms ease-in-out;
}
.swap__image>img {
	width: .8em;
}
.swap__image:hover {
	background: #343638;
}
.from__title {
	font-family: 'Gilroy-Black';
	font-style: normal;
	font-weight: 900;
	font-size: 1.1em;
	line-height: 30px;
	color: #ffffff;
	margin-bottom: 1em;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.from-flex {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	justify-content: space-between;
}
.from__button {
	border-color: #5a5a5a;
	border-radius: 10px;
	border-width: 1px;
	border-style: solid;
	display: flex;
	justify-content: space-between;
	font-style: normal;
	font-weight: bold;
	font-size: 1em;
	color: #FFFFFF;
	padding: .4em;
	min-width: 5em;
	align-items: center;
	transition: 100ms ease-in-out;
}
.from__button:hover {
	background-color: #665e8144;
}
.from__button span {
	font-weight: 100;
	font-size: 16px;
}
.button__image {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
.button__image img {
	-o-object-fit: scale-down;
	object-fit: scale-down;
	margin-right: 7px;
}
.from__button img {
	width: 1.8em;
	object-fit: scale-down;
}
.from-block__text {
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	line-height: 25px;
	color: #333333;
	margin: 90px 0 50px 0;
}
.input-check {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-bottom: 50px;
	position: relative;
}
.inpit__check {
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 21px;
	line-height: 25px;
	text-align: center;
	color: #333333;
	border: 0;
	outline: 0;
	border-bottom: 2px solid #333333;
	background: transparent;
	width: 70%;
	display: block;
	padding-bottom: 10px;
}
.inpit__check::placeholder {
	opacity: 0.3;
}
.inpit__check::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color: #333333;
}
.inpit__check::-moz-placeholder { /* Firefox 19+ */
	color: #333333;
}
.inpit__check:-ms-input-placeholder { /* IE 10+ */
	color: #333333;
}
.inpit__check:-moz-placeholder { /* Firefox 18- */
	color: #333333;
}
.check__text {
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 21px;
	line-height: 21px;
	color: #ffffff;
	margin-bottom: 40px;
	margin-top: 20px;
	text-align: center;
}
.check-text {
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 900;
	font-size: 21px;
	line-height: 21px;
	color: #333333;
}
.check-text span {
	font-weight: 100;
	font-size: 18px;
	border: 1px solid #000000;
	border-radius: 20px;
	padding: 5px 15px;
	margin-left: 15px;
}
.from-button {
	margin: 20px auto 40px auto;
}
input.ok + label::after {
	position: absolute;
	content: "";
	background: url(../image/el_ok.png);
	right: 180px;
	width: 29px;
	height: 29px;
	background-size: cover;
}
.modal-title {
	margin-bottom: 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-around;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.modal__title {
	font-family: 'Gilroy-Black';
	font-style: normal;
	font-weight: 900;
	font-size: 24px;
	line-height: 30px;
	color: #ffffff;
}
.modal__button {
	background: #400F81;
	border-radius: 50px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: bold;
	font-size: 21px;
	line-height: 21px;
	color: #FFFFFF;
	padding: 8px 15px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.modal__button .button__image {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.modal__button .button__image img {
	width: 29px !important;
	height: 29px !important;
	margin-left: 0;
}
.modal__button img {
	width: 18px;
	height: 13px;
	margin-left: 7px;
}
.modal {
	width: 800px;
}
.search {
	position: relative;
	margin-top: 15px;
	margin-bottom: 20px;
}
.search img {
	position: absolute;
	left: 0;
	top: -10px;
}
.input__search {
	width: 100%;
	padding: 0 0 2px 36px;
	border: 0;
	outline: 0;
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 21px;
	line-height: 25px;
	color: #333333;
	opacity: 0.5;
	border-bottom: 2px solid #333333;
}
.input__search::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color: #333333;
}
.input__search::-moz-placeholder { /* Firefox 19+ */
	color: #333333;
}
.input__search:-ms-input-placeholder { /* IE 10+ */
	color: #333333;
}
.input__search:-moz-placeholder { /* Firefox 18- */
	color: #333333;
}
.result {
	display: flex;
	height: 100%;
	flex-grow: 1;
	flex-direction: column;
	align-items: center;

}
.result-item {
	border: 2px solid #F2F2F2;
	border-radius: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 10px 30px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 7px;
	cursor: pointer;
}
.result-item__image {
	width: 53px;
	height: 53px;
}
.result-item__image img {
	width: 53px;
	height: 53px;
	-o-object-fit: scale-down;
	object-fit: scale-down;
}
.result-item__text {
	display: block;
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: bold;
	font-size: 21px;
	line-height: 21px;
	color: #333333;
	margin-left: 14px;
}
.result-item__text span {
	font-size: 16px;
	font-weight: 100;
}
.m-btn {
	display: none;
}
.farm-list .active span {
	background: #400F81;
	color: #fff;
}
.farm-list .active img {
	filter: invert(100%) sepia(0%) saturate(7427%) hue-rotate(23deg) brightness(118%) contrast(118%);
}
.farm-list-item {
	margin-bottom: 20px;
}
.farm-list-item-link span {
	vertical-align: middle;
	display: inline-flex;
	padding: 5px 15px;
	border-radius: 20px;
	color: #000;
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 28px;
}
.farm-list-item-link div {
	display: flex;
	align-items: center;
}
.farm-list-item-link:hover span {
	background: #400F81;
	color: #fff;
}
.farm-list-item-link:hover img {
	filter: invert(100%) sepia(0%) saturate(7427%) hue-rotate(23deg) brightness(118%) contrast(118%);
}
.farm-list-item-link img {
	margin-right: 15px;
	object-fit: scale-down;
}
.farm-nav-list {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
}
.farm-nav-list .active {
	background: #242628;
	color: #fff;
}
.farm-nav-list .active img {
	filter: invert(100%) sepia(6%) saturate(11%) hue-rotate(332deg) brightness(117%) contrast(100%);
}
.farm-nav-list-item {
	margin-right: 10px;
}
.farm-nav-list-item-link {
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 28px;
	display: flex;
	align-items: center;
	color: #000;
	padding: 2px 30px;
	border: 1px #000 solid;
	border-radius: 20px;
}
.farm-nav-list-item-link:hover {
	background: #400F81;
	color: #fff;
}
.farm-nav-list-item-link:hover img {
	filter: invert(100%) sepia(6%) saturate(11%) hue-rotate(332deg) brightness(117%) contrast(100%);
}
.farm-nav-list-item-link img {
	object-fit: scale-down;
	margin-left: 15px;
}
.farm-card {
	padding: 15px 15px 25px 15px;
	background: #FFFFFF;
	box-shadow: 0px 3px 4px rgba(0, 16, 71, 0.25);
	border-radius: 20px;
	margin: 15px auto auto auto;
}
.farm-card__top {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.farm-card__top-item {
	width: 100%;
}
.farm-card__top-item-top {
	display: flex;
}
.farm-card__top-item-bottom__title {
	width: 100%;
}
.farm-card__middle {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 30px;
}
.farm-card__middle__item {
	margin: 0 5px 0 5px;
	width: 100%;
}
.farm-card__middle__item__title {
	font-family: 'Gilroy-Black';
	font-style: normal;
	font-weight: 900;
	font-size: 24px;
	line-height: 30px;
	color: #333333;
}
.farm-card__middle__item__text {
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #333333;
}
.farm-card__bottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 30px;
}
.farm-card__bottom__apy {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 70%;
	background: #400F81;
	border-radius: 20px;
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	color: #F2F2F2;
	margin-right: 15px;
	padding: 8px 22px;
}
.farm-card__bottom__apy span {
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 900;
	font-size: 24px;
	line-height: 30px;
}
.farm-card__bottom__select {
	display: block;
	width: 40%;
	text-align: center;
	padding: 12.5px 18px;
	background: linear-gradient(109.8deg, #420F85 0%, #250551 80.01%, #0A0783 100%);
	box-shadow: 0px 3px 4px rgba(0, 16, 71, 0.25);
	border-radius: 50px;
	font-family: 'Gilroy-Black';
	font-style: normal;
	font-weight: 900;
	font-size: 21px;
	line-height: 21px;
	text-align: center;
	color: #F2F2F2;
}
.farm-card__top-item-top__item {
	background: #400F81;
	border-radius: 20px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	padding: 3px 20px;
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	color: #F2F2F2;
}
.farm-card__top-item-top__item-2 {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	margin-left: 1px;
}
.farm-card__top-item-bottom__title {
	margin-top: 30px;
	font-family: 'Gilroy-Black';
	font-style: normal;
	font-weight: 900;
	font-size: 36px;
	line-height: 45px;
	color: #333333;
}
.farm-card__top-item-bottom__number {
	background: #400F81;
	border-radius: 20px;
	padding: 3px 20px;
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	color: #F2F2F2;
	margin-top: 10px;
	text-align: center;
}
.farm__image img {
	width: 100%;
	height: 134px;
	object-fit: scale-down;
}
.farm-card-m-image {
	display: none;
}
.farm-list-item-m {
	display: none;
}
.dash-flex {
	background: #FFFFFF;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 75px 16px 35px;
	box-shadow: 0px 3px 4px rgba(0, 16, 71, 0.25);
	border-radius: 20px;
	margin-bottom: 25px;
}
.dash__title {
	display: flex;
	align-items: center;
	font-family: 'Gilroy-Black';
	font-style: normal;
	font-weight: 900;
	font-size: 24px;
	line-height: 30px;
	color: #333333;
}
.dash__title img {
	margin-right: 25px;
}
.dash-btn {
	background: linear-gradient(109.8deg, #420F85 0%, #250551 80.01%, #0A0783 100%);
	box-shadow: 0px 3px 4px rgba(0, 16, 71, 0.25);
	border-radius: 50px;
	font-family: 'Gilroy-Black';
	font-style: normal;
	font-weight: 900;
	font-size: 24px;
	line-height: 30px;
	text-align: center;
	color: #F2F2F2;
	padding: 8px 85px;
	cursor: pointer;
}
.dash {
	padding-bottom: 50px;
}
.form-claim {
	background: #FFFFFF;
	padding: 30px 45px;
	box-shadow: 0px 3px 4px rgba(0, 16, 71, 0.25);
	border-radius: 20px;
	margin-bottom: 15px;
}
.form-claim__title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 25px;
	background: #400F81;
	border-radius: 20px;
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	color: #F2F2F2;
	margin-bottom: 10px;
}
.form-claim__title span {
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 900;
	font-size: 24px;
	line-height: 100%;
	color: #F2F2F2;
}
.form-claim-flex {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}
.form-claim__input {
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	color: #333333;
	opacity: 0.5;
	border: 0;
	width: 100%;
	margin-right: 20px;
	margin-left: 10px;
	padding: 8px;
	border-bottom: 1px solid #333333;
}
.form-claim__input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color: #333333;
	opacity: 1;
}
.form-claim__input::-moz-placeholder { /* Firefox 19+ */
	color: #333333;
	opacity: 1;
}
.form-claim__input:-ms-input-placeholder { /* IE 10+ */
	color: #333333;
	opacity: 1;
}
.form-claim__input:-moz-placeholder { /* Firefox 18- */
	color: #333333;
	opacity: 1;
}
.form-claim__btn {
	background: linear-gradient(109.8deg, #420F85 0%, #250551 80.01%, #0A0783 100%);
	box-shadow: 0px 3px 4px rgba(0, 16, 71, 0.25);
	border-radius: 20px;
	padding: 10px 30px;
	font-family: 'Gilroy-Black';
	font-style: normal;
	font-weight: 900;
	font-size: 21px;
	line-height: 21px;
	text-align: center;
	color: #F2F2F2;
}
.statistics {
	background: #FFFFFF;
	padding: 30px 45px;
	box-shadow: 0px 3px 4px rgba(0, 16, 71, 0.25);
	border-radius: 20px;
	margin-bottom: 15px;
}
.statistics__title {
	display: flex;
	align-items: center;
	font-family: 'Gilroy-Black';
	font-style: normal;
	font-weight: 900;
	font-size: 24px;
	line-height: 30px;
	color: #333333;
	margin-bottom: 25px;
}
.statistics__title img {
	margin-right: 10px;
}
.statistics-title {
	margin-bottom: 5px;
	font-family: 'Gilroy-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 21px;
	line-height: 25px;
	color: #333333;
}
.statistics-progress {
	background: #400F81;
	border-radius: 20px;
	height: 46px;
	margin-bottom: 10px;
}
.progress-block {
	padding: 0 40px;
}
.ReactModal__Overlay {
    opacity: 0;
    transition: 100ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}
.container {
	user-select: none;
	display: flex;
	flex-direction: column;
	margin: 0 10%;
}

.mode-select {
	margin-bottom: 1em;
}

.balance {
	color: #5a5a5a;
	font-size: 14px;
}
.max {
	width: 50px;
	height: 30px;
	align-self: center;
	border-radius: 10px;
	margin-left: 10px;
	font-size: 10px;
	align-content: center;
	text-align: center;
	padding: 0;
	border: 1px solid #5a5a5a00;
}

.centered {
	width: 100%;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	align-content: center;
	align-items: center;
}

.fullwidth {
	width: 100%;
}

.bottom {
	margin-top: auto;
}

.grow {
	flex-grow: 1;
}

.modal-content {
	display: flex;
	flex-direction: column;
}

.btn:disabled, button[disabled] {
	opacity: 0.2;
	cursor: default;
}

.tos {
	background: #222;
	padding: 5px;
	margin-top: 15px;
	margin-bottom: 15px;
	width: 100%;
}
.tos::-webkit-scrollbar {
	width: 12px;               /* width of the entire scrollbar */
}

.tos::-webkit-scrollbar-track {
	background: #222;        /* color of the tracking area */
}

.tos::-webkit-scrollbar-thumb {
	background-color: white;    /* color of the scroll thumb */
	border-radius: 20px;       /* roundness of the scroll thumb */
	border: 3px solid black;  /* creates padding around scroll thumb */
}

.mode-button {
	font-family: Gilroy-Medium;
	width: 4em;
	background: none;
	color: white;
	font-size: 1.5em;
}

.mode-button.active {
	font-weight: bolder;
}

.logo-wrap {
	display: flex;
	flex-direction: row;
}

.beta {
	padding: 0px 10px;
	background-color: blueviolet;
	border-radius: 16px;
	align-self: center;
	margin-left: .3em;
}

.tvl {
	background: #242628;
	box-shadow: rgb(21 28 35) 0px 5px 3px 1px;
	border-width: 1px;
	border-color: #4A4A4A;
	border-style: solid;
	border-radius: 20px;
	padding: 10px 20px;
}

.net-container {
	margin-top: 2em;
}
.net-container>* {
	font-family: Gilroy, sans-serif;
	font-weight: bold;
	font-size: 1em;
}

.net-container-net {
	margin-bottom: 1em;
}

.net-container-token {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	padding-left: .4em;
	margin-bottom: .2em;
	margin-top: .2em;
}

.currency {
	font-family: 'Roboto', monospace;
}