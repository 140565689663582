.mobile-menu {
	display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-pack: justify;
-ms-flex-pack: justify;
justify-content: space-between;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
padding: 20px 0;
width: 100%;
}
.hamburger-icon-wrapper {
height: 46px;
width: 26px;
cursor: pointer;
-webkit-transform: translateY(-15px);
transform: translateY(-15px);
z-index: 10;
position:relative;
right: 10px;
top: 8px;
}
.hamburger-icon, .hamburger-icon:before, .hamburger-icon:after {
content: '';
position: absolute;
height: 2px;
width: 33px;
background: #000;
-webkit-transition: all 0.2s ease;
transition: all 0.2s ease }
.hamburger-icon        { top: 1.75em  }
.hamburger-icon:before { top: -0.6em }
.hamburger-icon:after  { top: 0.6em  }
.hamburger-active .hamburger-icon { background: transparent; -webkit-transform: rotate(-135deg); transform: rotate(-135deg) }
.hamburger-active .hamburger-icon:before, .hamburger-active .hamburger-icon:after { top: 0 }
.hamburger-active .hamburger-icon:before { -webkit-transform: rotate(90deg); transform: rotate(90deg) }
.hamburger-active .hamburger-icon, .hamburger-active .hamburger-icon:before, .hamburger-active .hamburger-icon:after {
background: #000;height: 4px;}
.hamburger-menu {
	width: 370px;
	height: 100%;
	position: fixed;
	margin: 0;
	right: -4000px;
	-webkit-transition: right 0.3s;
	-moz-transition: right 0.3s;
	transition: right 0.3s;
	background: #fff;
	z-index: 9;
	top: 0;
  padding: 105px 0 0 80px;
  border-bottom-left-radius: 50px;
  box-shadow: 0px 3px 4px rgba(0, 16, 71, 0.25);
}
.hamburger-active .hamburger-menu {
	right: 0;
	overflow-y: auto;
}
