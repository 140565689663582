@media screen and (max-width: 992px) {
	.from__button {
		font-size: 1em;
		min-width: 5.5em;
	}
	.swap {
		margin: 0 15%;
	}
	body {
		background-size: 100% 100% ;
		background-repeat: no-repeat;
	}
	main {
		background: none;
	}
	header {
	}
	.top-header {
		flex-wrap: wrap;
		justify-content: center;
	}
	.m-logo img {
		width: 103px;
		height: 52px;
		-o-object-fit: scale-down;
		object-fit: scale-down;
	}
	.title-h1 {
		font-weight: 500;
		font-size: 55px;
		line-height: 65px;
		margin: 30px 0 0 0;
	}
	.main__text {
		margin: 25px 150px 25px 150px;
		font-weight: 500;
		font-size: 21px;
		line-height: 25px;
	}
	.main-btn-group {
		margin: 0 135px;
	}
	.main-btn-group .btn:nth-child(1) {
		margin-right: 35px;
	}
	.number-card {
		margin-bottom: 0;
	}
	.number {
		padding: 20px 0 40px 0;
	}
	.number-card__number {
		font-size: 48px;
		line-height: 56px;
	}
	.number-card__text {
		font-weight: 500;
		font-size: 21px;
		line-height: 25px;
	}
	.title-h2 {
		font-weight: 500;
		font-size: 36px;
		line-height: 42px;
		margin: 0;
	}
	.what {
		padding: 60px 0;
	}
	.what-flex {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		margin: 0 30px;
	}
	.flex-item {
		width: 30%;
		margin-bottom: 10px;
	}
	.btn-center {
		margin-top: 5px;
	}
	.zyx__text {
		margin: 15px 0 35px 0;
	}
	.margin {
		margin: 0;
	}
	.logo-wrap>a>img {
		max-width: 260px;
	}
	.join {
		margin: 60px 0 70px 0;
		padding-bottom: 60px;
	}
	.join .row {
		margin: 25px 50px 0 50px
	}
	.join-card {
		margin-bottom: 25px;
	}
	.mobile-list {
		margin-bottom: 20px;
	}
	.mobile-list-item {
		margin-bottom: 10px;
	}
	.mobile-list-item-link {
		font-family: 'Gilroy-Medium';
		font-style: normal;
		font-weight: 900;
		font-size: 18px;
		line-height: 22px;
		color: #333333;
	}
	.mobile-list__title {
		font-family: 'Gilroy-Medium';
		font-style: normal;
		font-weight: 900;
		font-size: 18px;
		line-height: 30px;
		color: #000000;
		margin-bottom: 10px;
	}
	.mobile-list-block {
		margin-bottom: 20px;
	}
	.mobile-list-block-list-item {
		margin-bottom: 10px;
	}
	.mobile-list-block-list-item-link {
		font-family: 'Gilroy-Medium';
		font-style: normal;
		font-weight: 100;
		font-size: 18px;
		line-height: 30px;
		color: #000000;
	}
	.wallet-card {
		margin-bottom: 25px;
	}
	.from-input {
		width: 60%;
	}
	.inpit__check {
		width: 100%;
	}
	input.ok + label::after { 
		right: 0;
	}
	.modal {
		width: 700px;
	}
	.farm-list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
	.farm {
		padding-top: 30px;
	}
	.farm-list-item {
		margin-right: 25px;
	}
	.farm-list {
		margin-bottom: 20px;
	}
	.farm-nav-list-item-link {
		padding: 2px 15px;
	}
	.farm-card__top {
		flex-direction: column;
	}
	.farm-card__bottom {
		flex-direction: column;
	}
	.farm-card__bottom__apy {
		margin-bottom: 25px;
	}
	.farm-card-m-image {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 50px;
	}
	.farm-card__top-item-bottom__title {
		text-align: center;
	}
	.farm-card__top-item-bottom__number {
		margin: 0 25px;
		padding: 4px 25px;
		margin-top: 25px;
	}
	.farm-card__bottom__apy {
		width: 100%;
		margin-right: 0;
	}
	.farm__image{
		display: none;
	}
	.farm-card__bottom__select {
		width: 50%;
		display: block;
	}
	.farm-card__middle__item__title {
		font-size: 20px;
	}
	.dash-flex {
		padding: 16px 35px;
	}
	.form-claim {
		padding: 30px 80px;
	}
	.form-claim__btn {
		padding: 10px 50px;
		outline: none;
		border: none;
	}
	.dash {
		margin-top: 50px;
	}
	.statistics {
		margin-top: 25px;
	}
}
@media screen and (max-width: 576px) {
	.swap {
		margin: 3%;
	}
	.title-h1 {
		font-size: 36px;
		line-height: 42px;
	}
	.main__text {
		font-size: 18px;
		line-height: 21px;
	}
	.main__text {
		margin: 25px 0;
	}
	.main-btn-group {
		margin: 0;
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.main-btn-group .btn:nth-child(1) {
		margin: 0 0 10px 0;
	}
	.number {
		display: none;
	}
	.title-h2 {
		font-size: 24px;
		line-height: 28px;
	}
	.slide__text {
		font-size: 18px;
		line-height: 21px;
	}
	.slide__image img {
		width: 100%;
		height: 125px;
	}
	.what {
		padding: 30px 0;
	}
	.flex-item {
		width: 48%;
	}
	.what-flex {
		margin: 0;
	}
	.flex-item:nth-child(2n) {
		margin-right: 0;
	}
	.btn-center {
		margin: 0 25px;
	}
	.join {
		margin: 35px 0;
	}
	.join .row {
		margin: 25px 0 0 0;
	}
	.hamburger-menu {
		width: 100%;
		padding: 90px 0 0 90px;
	}
	.wallet-card-img {
		margin: 0;
	}
	.wallet-card-img__image img {
		width: 100%;
	}
	.modal .modal__button {
		display: none;
	}
	.modal__title {
		text-align: center;
		margin-bottom: 25px;
		width: 100%;
	}
	.swap__image {
	}
	.from {
		background: #fff;
		margin: 0 -15px;
	}
	.balance-container {
		flex-basis: 100%;
	}
	.m-btn {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.d-btn {
		display: none;
	}
	.from__title {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.from__input {
		width: 100%;
	}
	.from-input {
		width: 100%;
	}
	.inpit__check {
		font-size: 18px;
		line-height: 21px;
		padding-bottom: 10px;
		opacity: 0.6;
	}
	.check__text {
		font-size: 18px;
		line-height: 21px;
	}
	.from-button {
		width: 80%;
	}
	.farm-nav-list {
		display: none;
	}
	.farm-list-item-link div {
		font-size: 18px;
		line-height: 21px;
	}
	.farm-list-item {
		margin-right: 5px;
	}
	.farm-list-item-link img {
		margin-right: 5px;
	}
	.farm-list-item-m {
		display: block;
		margin-left: 25px;
	}
	.farm-nav-list-item {
		margin-top: 25px;
	}
	.d-block {
		display: block;
	}
	.farm-nav-list-item-link {
		display: flex;
		justify-content: space-between;
	}
	.dash__title img {
		display: none;
	}
	.dash-flex {
		flex-direction: column;
	}
	.dash__title {
		width: 100%;
		padding-left: 30px;
	}
	.dash-flex {
		padding: 20px 0;
		margin-right: -15px;
		margin-left: -15px;
		border-radius: 0;
	}
	.dash-btn {
		width: 90%;
		margin-top: 10px;
	}
	.form-claim {
		padding: 30px 30px;
		margin-right: -15px;
		margin-left: -15px;
		border-radius: 0;
	}
	.form-claim-flex {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.form-claim__btn {
		width: 100%;
	}
	.form-claim__input {
		width: 100%;
		margin: 15px 0 35px 0;
	}
	.statistics {
		padding: 30px 15px;
		margin-right: -15px;
		margin-left: -15px;
		border-radius: 0;
	}
	.progress-block {
		padding: 0 10px;
	}
}

@media screen and (min-width: 400px) {
	.swap {
		transform: scale(1.1);
	}
}

@media screen and (max-width:  1920px) {
	.from-block {
		padding: .8em;
		margin-bottom: .3em;
	}
	.from__title {
		margin-bottom: .6em;
		padding: .2em .1em;
	}
}

@media screen and (min-width: 1900px) {
	.swap {
		max-width: 450px;
	}
}