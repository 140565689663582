.rc-steps {
    font-size: 0;
    width: 100%;
    line-height: 1.5;
    display: flex;
}

.rc-steps,
.rc-steps * {
    box-sizing: border-box;
}

.rc-steps-item {
    position: relative;
    display: inline-block;
    vertical-align: top;
    flex: 1;
    overflow: hidden;
}

.rc-steps-item:last-child {
    flex: none;
}

.rc-steps-item:last-child .rc-steps-item-tail,
.rc-steps-item:last-child .rc-steps-item-title:after {
    display: none;
}

.rc-steps-item-icon,
.rc-steps-item-content {
    display: inline-block;
    vertical-align: top;
}

.rc-steps-item-icon {
    border: 1px solid #ccc;
    width: 26px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    border-radius: 26px;
    font-size: 14px;
    margin-right: 8px;
    transition: background-color 0.3s, border-color 0.3s;
}

.rc-steps-item-icon>.rc-steps-icon {
    line-height: 1;
    top: -1px;
    color: #108ee9;
    position: relative;
}

.rc-steps-item-icon>.rc-steps-icon.rcicon {
    font-size: 12px;
    position: relative;
    top: -2px;
}

.rc-steps-item-tail {
    position: absolute;
    left: 0;
    width: 100%;
    top: 12px;
    padding: 0 10px;
}

.rc-steps-item-tail:after {
    content: '';
    display: inline-block;
    background: #e9e9e9;
    height: 1px;
    border-radius: 1px;
    width: 100%;
    transition: background .3s;
}

.rc-steps-item-content {
    margin-top: 3px;
}

.rc-steps-item-title {
    font-size: 14px;
    margin-bottom: 4px;
    color: #fff;
    font-weight: bold;
    display: inline-block;
    padding-right: 10px;
    position: relative;
}

.rc-steps-item-title:after {
    content: '';
    height: 1px;
    width: 1000px;
    background: #35343e;
    display: block;
    position: absolute;
    top: 0.55em;
    left: 100%;
}

.rc-steps-item-description {
    font-size: 12px;
    color: #999;
}

.rc-steps-item-wait .rc-steps-item-icon {
    border-color: #35343e;
    background-color: #35343e;
}

.rc-steps-item-wait .rc-steps-item-icon>.rc-steps-icon {
    color: #ccc;
}

.rc-steps-item-wait .rc-steps-item-icon>.rc-steps-icon .rc-steps-icon-dot {
    background: #ccc;
}

.rc-steps-item-wait .rc-steps-item-title {
    color: rgba(0, 0, 0, 0.43);
}

.rc-steps-item-wait .rc-steps-item-title:after {
    background-color: #e9e9e9;
}

.rc-steps-item-wait .rc-steps-item-description {
    color: rgba(0, 0, 0, 0.43);
}

.rc-steps-item-wait .rc-steps-item-tail:after {
    background-color: #35343e;
}

.rc-steps-item-process .rc-steps-item-icon {
    border-color: #108ee9;
}

.rc-steps-item-process .rc-steps-item-icon>.rc-steps-icon {
}

.rc-steps-item-process .rc-steps-item-icon>.rc-steps-icon .rc-steps-icon-dot {
    background: #108ee900;
}

.rc-steps-item-process .rc-steps-item-title {
    color: #0ff;
}

.rc-steps-item-process .rc-steps-item-title:after {
    background-color: #35343e;
}

.rc-steps-item-process .rc-steps-item-description {
    color: rgba(0, 0, 0, 0.65);
}

.rc-steps-item-process .rc-steps-item-tail:after {
    background-color: #35343e;
}

.rc-steps-item-process .rc-steps-item-icon {
    background: #108ee9;
}

.rc-steps-item-process .rc-steps-item-icon>.rc-steps-icon {
    color: #0ff;
}

.rc-steps-item-finish .rc-steps-item-icon {
    border-color: #108ee9;
    background-color: #108ee9;

}

.rc-steps-item-finish .rc-steps-item-icon>.rc-steps-icon {
    color: #108ee9;
}

.rc-steps-item-finish .rc-steps-item-icon>.rc-steps-icon .rc-steps-icon-dot {
    background: #108ee9;
}

.rc-steps-item-finish .rc-steps-item-title {
    color: gba(0, 0, 0, 0.43);
}

.rc-steps-item-finish .rc-steps-item-title:after {
    background-color: #108ee9;
}

.rc-steps-item-finish .rc-steps-item-description {
    color: gba(0, 0, 0, 0.43);
}

.rc-steps-item-finish .rc-steps-item-tail:after {
    background-color: #108ee9;
}

.rc-steps-item-error .rc-steps-item-icon {
    border-width: 4px;
    border-color: #f50;
    background-color: #00000000;
}

.rc-steps-item-error .rc-steps-item-icon>.rc-steps-icon {
    color: #f50;
}

.rc-steps-item-error .rc-steps-item-icon>.rc-steps-icon .rc-steps-icon-dot {
    background: #f50;
}

.rc-steps-item-error .rc-steps-item-title {
    color: #f50;
}

.rc-steps-item-error .rc-steps-item-title:after {
    background-color: #f50;
}

.rc-steps-item-error .rc-steps-item-description {
    color: #f50;
}

.rc-steps-item-error .rc-steps-item-tail:after {
    background-color: #f50;
}

.rc-steps-item.rc-steps-next-error .rc-steps-item-title:after {
    background: #f50;
}

.rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item {
    margin-right: 10px;
}

.rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item:last-child {
    margin-right: 0;
}

.rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item-tail {
    display: none;
}

.rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item-description {
    max-width: 100px;
}

.rc-steps-item-custom .rc-steps-item-icon {
    background: none;
    border: 0;
    width: auto;
    height: auto;
}

.rc-steps-item-custom .rc-steps-item-icon>.rc-steps-icon {
    font-size: 20px;
    top: 1px;
    width: 20px;
    height: 20px;
}

.rc-steps-item-custom.rc-steps-item-process .rc-steps-item-icon>.rc-steps-icon {
    color: rgb(98, 126, 234);
}

.rc-steps-label-vertical .rc-steps-item {
    overflow: visible;
}

.rc-steps-label-vertical .rc-steps-item-tail {
    padding: 0px 24px;
    margin-left: 48px;
}

.rc-steps-label-vertical .rc-steps-item-content {
    display: block;
    text-align: center;
    margin-top: 8px;
    width: 100px;
}

.rc-steps-label-vertical .rc-steps-item-icon {
    display: inline-block;
    margin-left: 36px;
}

.rc-steps-label-vertical .rc-steps-item-title {
    padding-right: 0;
}

.rc-steps-label-vertical .rc-steps-item-title:after {
    display: none;
}

.rc-steps-label-vertical .rc-steps-item-description {
    text-align: left;
}

.rc-steps-small .rc-steps-item-icon {
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    border-radius: 18px;
    font-size: 12px;
    margin-right: 10px;
}

.rc-steps-small .rc-steps-item-icon>.rc-steps-icon {
    font-size: 12px;
    font-size: 9px \9;
    transform: scale(0.75);
    top: -1px;
}

.rc-steps-small .rc-steps-item-content {
    margin-top: 0;
}

.rc-steps-small .rc-steps-item-title {
    font-size: 12px;
    margin-bottom: 4px;
    color: #fff;
    font-weight: bold;
}

.rc-steps-small .rc-steps-item-description {
    font-size: 12px;
    color: #999;
}

.rc-steps-small .rc-steps-item-tail {
    top: 8px;
    padding: 0 8px;
}

.rc-steps-small .rc-steps-item-tail:after {
    height: 1px;
    border-radius: 1px;
    width: 100%;
}

.rc-steps-small .rc-steps-item-custom .rc-steps-item-icon {
    width: inherit;
    height: inherit;
    line-height: inherit;
    border-radius: 0;
    border: 0;
    background: none;
}

.rc-steps-small .rc-steps-item-custom .rc-steps-item-icon>.rc-steps-icon {
    font-size: 20px;
    top: -2.5px;
    transform: none;
}

.rc-steps-vertical {
    display: block;
}

.rc-steps-vertical .rc-steps-item {
    display: block;
    overflow: visible;
}

.rc-steps-vertical .rc-steps-item-icon {
    float: left;
}

.rc-steps-vertical .rc-steps-item-icon-inner {
    margin-right: 16px;
}

.rc-steps-vertical .rc-steps-item-content {
    min-height: 48px;
    overflow: hidden;
    display: block;
}

.rc-steps-vertical .rc-steps-item-title {
    line-height: 26px;
}

.rc-steps-vertical .rc-steps-item-title:after {
    display: none;
}

.rc-steps-vertical .rc-steps-item-description {
    padding-bottom: 12px;
}

.rc-steps-vertical .rc-steps-item-tail {
    position: absolute;
    left: 13px;
    top: 0;
    height: 100%;
    width: 1px;
    padding: 30px 0 4px 0;
}

.rc-steps-vertical .rc-steps-item-tail:after {
    height: 100%;
    width: 1px;
}

.rc-steps-vertical.rc-steps-small .rc-steps-item-tail {
    position: absolute;
    left: 9px;
    top: 0;
    padding: 22px 0 4px 0;
}

.rc-steps-vertical.rc-steps-small .rc-steps-item-title {
    line-height: 18px;
}